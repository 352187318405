#blogcard_markdown h3 {
    font-size: 20px;
    font-weight: 200;
    margin-top: 0;
}
#blogcard_markdown p {
    font-size: 18px;
    color: #4e4d4d;
}
.blog-body h3 {
    font-size: 34px;
    color: rgb(84, 171, 246);
}
.blog-body p {
    font-size: 20px;
    margin-top: 20px;
    color: #fff;
}
.blog-body p:last-of-type {
    margin-bottom: 20px;
    color: #fff;
}
.blog-body pre {
    padding: 20px;
    background-color: #2f2f2f;
    font-size: 18px;
    color: #fff;
    border-radius: 7px;
    overflow-x: auto;
    margin: 20px 0;
}
.blog-body {
    width: 90%;
    margin: 0 auto;
}
.blog-body strong,
#blogcard_markdown strong {
    color: rgb(84, 171, 246);
}
.blog-body ul li{
    list-style: circle;
    font-size: 20px;
    color: #fff;
}
@media only screen and (max-width: 600px) {
    .blog-body h5 {
        font-size: 28px;
    }
    .blog-body p {
        font-size: 16px;
        line-height: 26px;
    }
}