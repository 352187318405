.loaderContainer{
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 100;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
  }
  .innerLoaderContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0 20px 0;
    box-sizing: border-box;
  }
  .loader {
    animation-timing-function: ease;
    animation-name: fadeIn;
    animation-delay: 1s;
    position: relative;

  }
  .loader .center {
    width: 90px;
    height: 90px;
    background:#54abf6;
    border-radius: 50%;
    animation: center 3.2s ease-in-out infinite;
    list-style: none;
    top: -22px;
    position: relative;
  }
  .loader .item {
    position: absolute;
    width: 25px;
    height: 25px;
    top: 15px;
    left: 0;
    right: 0;
    margin: auto;
    background:#54abf6;
    border-radius: 50%;
    list-style: none;
  }
  .loadingTitle {
    top: 100%;
    position: relative;
    font-size: 20px;
    z-index: 12;
    color:#2b2b2b;
  }
  .item-1 {
    animation: anim-1 3.2s ease-in-out infinite 0.2s;
    animation-fill-mode: backwards;
  }
  
  @keyframes anim-1 {
    0%, 60%, 100% {
      transform: rotate(45deg) translateX(70px) scale(1);
    }
    10%, 50% {
      transform: rotate(45deg) translateX(0) scale(1.5);
    }
  }
  .item-2 {
    animation: anim-2 3.2s ease-in-out infinite 0.4s;
    animation-fill-mode: backwards;
  }
  
  @keyframes anim-2 {
    0%, 60%, 100% {
      transform: rotate(90deg) translateX(70px) scale(1);
    }
    10%, 50% {
      transform: rotate(90deg) translateX(0) scale(1.5);
    }
  }
  .item-3 {
    animation: anim-3 3.2s ease-in-out infinite 0.6s;
    animation-fill-mode: backwards;
  }
  
  @keyframes anim-3 {
    0%, 60%, 100% {
      transform: rotate(135deg) translateX(70px) scale(1);
    }
    10%, 50% {
      transform: rotate(135deg) translateX(0) scale(1.5);
    }
  }
  .item-4 {
    animation: anim-4 3.2s ease-in-out infinite 0.8s;
    animation-fill-mode: backwards;
  }
  
  @keyframes anim-4 {
    0%, 60%, 100% {
      transform: rotate(180deg) translateX(70px) scale(1);
    }
    10%, 50% {
      transform: rotate(180deg) translateX(0) scale(1.5);
    }
  }
  .item-5 {
    animation: anim-5 3.2s ease-in-out infinite 1s;
    animation-fill-mode: backwards;
  }
  
  @keyframes anim-5 {
    0%, 60%, 100% {
      transform: rotate(225deg) translateX(70px) scale(1);
    }
    10%, 50% {
      transform: rotate(225deg) translateX(0) scale(1.5);
    }
  }
  .item-6 {
    animation: anim-6 3.2s ease-in-out infinite 1.2s;
    animation-fill-mode: backwards;
  }
  
  @keyframes anim-6 {
    0%, 60%, 100% {
      transform: rotate(270deg) translateX(70px) scale(1);
    }
    10%, 50% {
      transform: rotate(270deg) translateX(0) scale(1.5);
    }
  }
  .item-7 {
    animation: anim-7 3.2s ease-in-out infinite 1.4s;
    animation-fill-mode: backwards;
  }
  
  @keyframes anim-7 {
    0%, 60%, 100% {
      transform: rotate(315deg) translateX(70px) scale(1);
    }
    10%, 50% {
      transform: rotate(315deg) translateX(0) scale(1.5);
    }
  }
  .item-8 {
    animation: anim-8 3.2s ease-in-out infinite 1.6s;
    animation-fill-mode: backwards;
  }
  
  @keyframes anim-8 {
    0%, 60%, 100% {
      transform: rotate(360deg) translateX(70px) scale(1);
    }
    10%, 50% {
      transform: rotate(360deg) translateX(0) scale(1.5);
    }
  }
  @keyframes center {
    0%, 10%, 90%, 100% {
      transform: scale(0.7);
    }
    45%, 55% {
      transform: scale(1);
    }
  }
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }