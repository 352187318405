@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap');

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}
body::-webkit-scrollbar {
  width: .4rem;
}
 
body::-webkit-scrollbar-track {
  background-color: #0F0F0F;
}
 
body::-webkit-scrollbar-thumb {
  background-color: #54ABF6;
  border-radius: 15px;
}
html, 
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  min-height: 100%;
  height: 100%;
  scroll-behavior: smooth;
  font-size: 2vw;
}
p,li,a {
  font-family: 'Open Sans', sans-serif;
}
#root {
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  background-color: #0F0F0F;
}


@keyframes animate {
  0%,100% {
    clip-path: polygon(0 51%, 24% 44%, 42% 56%, 68% 60%, 99% 50%, 100% 100%, 0% 100%);
  }
  50% {
    clip-path: polygon(0 45%, 24% 58%, 50% 56%, 69% 46%, 100% 44%, 100% 100%, 0% 100%);
  }
}