@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap);
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}
body::-webkit-scrollbar {
  width: .4rem;
}
 
body::-webkit-scrollbar-track {
  background-color: #0F0F0F;
}
 
body::-webkit-scrollbar-thumb {
  background-color: #54ABF6;
  border-radius: 15px;
}
html, 
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  min-height: 100%;
  height: 100%;
  scroll-behavior: smooth;
  font-size: 2vw;
}
p,li,a {
  font-family: 'Open Sans', sans-serif;
}
#root {
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  background-color: #0F0F0F;
}


@-webkit-keyframes animate {
  0%,100% {
    -webkit-clip-path: polygon(0 51%, 24% 44%, 42% 56%, 68% 60%, 99% 50%, 100% 100%, 0% 100%);
            clip-path: polygon(0 51%, 24% 44%, 42% 56%, 68% 60%, 99% 50%, 100% 100%, 0% 100%);
  }
  50% {
    -webkit-clip-path: polygon(0 45%, 24% 58%, 50% 56%, 69% 46%, 100% 44%, 100% 100%, 0% 100%);
            clip-path: polygon(0 45%, 24% 58%, 50% 56%, 69% 46%, 100% 44%, 100% 100%, 0% 100%);
  }
}


@keyframes animate {
  0%,100% {
    -webkit-clip-path: polygon(0 51%, 24% 44%, 42% 56%, 68% 60%, 99% 50%, 100% 100%, 0% 100%);
            clip-path: polygon(0 51%, 24% 44%, 42% 56%, 68% 60%, 99% 50%, 100% 100%, 0% 100%);
  }
  50% {
    -webkit-clip-path: polygon(0 45%, 24% 58%, 50% 56%, 69% 46%, 100% 44%, 100% 100%, 0% 100%);
            clip-path: polygon(0 45%, 24% 58%, 50% 56%, 69% 46%, 100% 44%, 100% 100%, 0% 100%);
  }
}
.loaderContainer{
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 100;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
  }
  .innerLoaderContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0 20px 0;
    box-sizing: border-box;
  }
  .loader {
    -webkit-animation-timing-function: ease;
            animation-timing-function: ease;
    -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
    position: relative;

  }
  .loader .center {
    width: 90px;
    height: 90px;
    background:#54abf6;
    border-radius: 50%;
    -webkit-animation: center 3.2s ease-in-out infinite;
            animation: center 3.2s ease-in-out infinite;
    list-style: none;
    top: -22px;
    position: relative;
  }
  .loader .item {
    position: absolute;
    width: 25px;
    height: 25px;
    top: 15px;
    left: 0;
    right: 0;
    margin: auto;
    background:#54abf6;
    border-radius: 50%;
    list-style: none;
  }
  .loadingTitle {
    top: 100%;
    position: relative;
    font-size: 20px;
    z-index: 12;
    color:#2b2b2b;
  }
  .item-1 {
    -webkit-animation: anim-1 3.2s ease-in-out infinite 0.2s;
            animation: anim-1 3.2s ease-in-out infinite 0.2s;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
  }
  
  @-webkit-keyframes anim-1 {
    0%, 60%, 100% {
      transform: rotate(45deg) translateX(70px) scale(1);
    }
    10%, 50% {
      transform: rotate(45deg) translateX(0) scale(1.5);
    }
  }
  
  @keyframes anim-1 {
    0%, 60%, 100% {
      transform: rotate(45deg) translateX(70px) scale(1);
    }
    10%, 50% {
      transform: rotate(45deg) translateX(0) scale(1.5);
    }
  }
  .item-2 {
    -webkit-animation: anim-2 3.2s ease-in-out infinite 0.4s;
            animation: anim-2 3.2s ease-in-out infinite 0.4s;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
  }
  
  @-webkit-keyframes anim-2 {
    0%, 60%, 100% {
      transform: rotate(90deg) translateX(70px) scale(1);
    }
    10%, 50% {
      transform: rotate(90deg) translateX(0) scale(1.5);
    }
  }
  
  @keyframes anim-2 {
    0%, 60%, 100% {
      transform: rotate(90deg) translateX(70px) scale(1);
    }
    10%, 50% {
      transform: rotate(90deg) translateX(0) scale(1.5);
    }
  }
  .item-3 {
    -webkit-animation: anim-3 3.2s ease-in-out infinite 0.6s;
            animation: anim-3 3.2s ease-in-out infinite 0.6s;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
  }
  
  @-webkit-keyframes anim-3 {
    0%, 60%, 100% {
      transform: rotate(135deg) translateX(70px) scale(1);
    }
    10%, 50% {
      transform: rotate(135deg) translateX(0) scale(1.5);
    }
  }
  
  @keyframes anim-3 {
    0%, 60%, 100% {
      transform: rotate(135deg) translateX(70px) scale(1);
    }
    10%, 50% {
      transform: rotate(135deg) translateX(0) scale(1.5);
    }
  }
  .item-4 {
    -webkit-animation: anim-4 3.2s ease-in-out infinite 0.8s;
            animation: anim-4 3.2s ease-in-out infinite 0.8s;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
  }
  
  @-webkit-keyframes anim-4 {
    0%, 60%, 100% {
      transform: rotate(180deg) translateX(70px) scale(1);
    }
    10%, 50% {
      transform: rotate(180deg) translateX(0) scale(1.5);
    }
  }
  
  @keyframes anim-4 {
    0%, 60%, 100% {
      transform: rotate(180deg) translateX(70px) scale(1);
    }
    10%, 50% {
      transform: rotate(180deg) translateX(0) scale(1.5);
    }
  }
  .item-5 {
    -webkit-animation: anim-5 3.2s ease-in-out infinite 1s;
            animation: anim-5 3.2s ease-in-out infinite 1s;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
  }
  
  @-webkit-keyframes anim-5 {
    0%, 60%, 100% {
      transform: rotate(225deg) translateX(70px) scale(1);
    }
    10%, 50% {
      transform: rotate(225deg) translateX(0) scale(1.5);
    }
  }
  
  @keyframes anim-5 {
    0%, 60%, 100% {
      transform: rotate(225deg) translateX(70px) scale(1);
    }
    10%, 50% {
      transform: rotate(225deg) translateX(0) scale(1.5);
    }
  }
  .item-6 {
    -webkit-animation: anim-6 3.2s ease-in-out infinite 1.2s;
            animation: anim-6 3.2s ease-in-out infinite 1.2s;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
  }
  
  @-webkit-keyframes anim-6 {
    0%, 60%, 100% {
      transform: rotate(270deg) translateX(70px) scale(1);
    }
    10%, 50% {
      transform: rotate(270deg) translateX(0) scale(1.5);
    }
  }
  
  @keyframes anim-6 {
    0%, 60%, 100% {
      transform: rotate(270deg) translateX(70px) scale(1);
    }
    10%, 50% {
      transform: rotate(270deg) translateX(0) scale(1.5);
    }
  }
  .item-7 {
    -webkit-animation: anim-7 3.2s ease-in-out infinite 1.4s;
            animation: anim-7 3.2s ease-in-out infinite 1.4s;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
  }
  
  @-webkit-keyframes anim-7 {
    0%, 60%, 100% {
      transform: rotate(315deg) translateX(70px) scale(1);
    }
    10%, 50% {
      transform: rotate(315deg) translateX(0) scale(1.5);
    }
  }
  
  @keyframes anim-7 {
    0%, 60%, 100% {
      transform: rotate(315deg) translateX(70px) scale(1);
    }
    10%, 50% {
      transform: rotate(315deg) translateX(0) scale(1.5);
    }
  }
  .item-8 {
    -webkit-animation: anim-8 3.2s ease-in-out infinite 1.6s;
            animation: anim-8 3.2s ease-in-out infinite 1.6s;
    -webkit-animation-fill-mode: backwards;
            animation-fill-mode: backwards;
  }
  
  @-webkit-keyframes anim-8 {
    0%, 60%, 100% {
      transform: rotate(360deg) translateX(70px) scale(1);
    }
    10%, 50% {
      transform: rotate(360deg) translateX(0) scale(1.5);
    }
  }
  
  @keyframes anim-8 {
    0%, 60%, 100% {
      transform: rotate(360deg) translateX(70px) scale(1);
    }
    10%, 50% {
      transform: rotate(360deg) translateX(0) scale(1.5);
    }
  }
  @-webkit-keyframes center {
    0%, 10%, 90%, 100% {
      transform: scale(0.7);
    }
    45%, 55% {
      transform: scale(1);
    }
  }
  @keyframes center {
    0%, 10%, 90%, 100% {
      transform: scale(0.7);
    }
    45%, 55% {
      transform: scale(1);
    }
  }
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
#blogcard_markdown h3 {
    font-size: 20px;
    font-weight: 200;
    margin-top: 0;
}
#blogcard_markdown p {
    font-size: 18px;
    color: #4e4d4d;
}
.blog-body h3 {
    font-size: 34px;
    color: rgb(84, 171, 246);
}
.blog-body p {
    font-size: 20px;
    margin-top: 20px;
    color: #fff;
}
.blog-body p:last-of-type {
    margin-bottom: 20px;
    color: #fff;
}
.blog-body pre {
    padding: 20px;
    background-color: #2f2f2f;
    font-size: 18px;
    color: #fff;
    border-radius: 7px;
    overflow-x: auto;
    margin: 20px 0;
}
.blog-body {
    width: 90%;
    margin: 0 auto;
}
.blog-body strong,
#blogcard_markdown strong {
    color: rgb(84, 171, 246);
}
.blog-body ul li{
    list-style: circle;
    font-size: 20px;
    color: #fff;
}
@media only screen and (max-width: 600px) {
    .blog-body h5 {
        font-size: 28px;
    }
    .blog-body p {
        font-size: 16px;
        line-height: 26px;
    }
}
h1,h2,h3,h4,h5,h6 {
    margin: 10px 0;
}

